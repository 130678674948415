// @flow

import React from 'react'
import type { Element } from 'react'

import List from './Applications/scenes/List'

class ApplicationsList extends React.PureComponent {
  static async getInitialProps({ query = {} }) {
    return { query }
  }

  render() {
    return <List query={this.props.query} />
  }
}

export default ApplicationsList
