// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table'
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import TextField from 'material-ui/TextField'
import DropDownMenu from 'material-ui/DropDownMenu'
import RaisedButton from 'material-ui/RaisedButton'
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'
import MenuItem from 'material-ui/MenuItem'
import NavigationExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more'
import Snackbar from 'material-ui/Snackbar'
import CircularProgress from 'material-ui/CircularProgress'

import Pagination from 'components/Pagination'

import AyuApiClient from 'services/api/AyuApiClient'

import { MuiThemeProvider } from 'material-ui'
import GetMuiTheme from 'material-ui/styles/getMuiTheme'
import style from './style.css'

function debounce(func, time) {
  return function() {
    const element = this
    const args = arguments

    clearTimeout(element.timeout)
    element.timeout = setTimeout(function() {
      func.apply(element, Array.from(args))
    }, time || 300)
  }
}

type State = {
  message: boolean | string,
  selected: number[],
  data: Object[],
  meta: ?Object,
  filterValue: string,
  searchText: string,
  loading: boolean,
  requestId: number,
}

const theme = GetMuiTheme({
  // tableRowColumn: {
  //   spacing: 0,
  // },
})

class Grid extends Component<any, State> {
  constructor(props) {
    super()

    let defaultFilter = ''
    if (props.filters.length > 0) {
      defaultFilter = props.filters[0].value
    }

    this.state = {
      message: false,
      selected: [],
      data: [],
      meta: null,
      filterValue: defaultFilter,
      searchText: '',
      loading: true,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    const { page } = this.props
    const { page: prevPage } = prevProps

    if (page !== prevPage) {
      this.fetchData()
    }
  }

  fetchData = () => {
    const { includes, page, resourceType, withDeleted } = this.props
    const { filterValue, searchText } = this.state

    const filters = []
    if (filterValue) {
      filters.push(filterValue)
    }

    if (searchText) {
      filters.push(`search:${searchText}`)
    }

    const params = {
      includes,
      filters,
      page,
    }

    if (withDeleted) {
      params.deleted = 1
    }

    const requestId = Date.now()

    this.setState(
      {
        loading: true,
        requestId,
      },
      async () => {
        try {
          const result = await AyuApiClient.get(resourceType, params, { requestId })
          if (result.config.requestId !== this.state.requestId) {
            return
          }

          this.setState({
            loading: false,
            data: result.data || [],
            meta: result.meta,
          })
        } catch (result) {
          this.setState({
            error: result.data,
          })
        }
      }
    )
  }

  onActionError = (message) => {
    this.setState({
      message,
      loading: false,
    })

    // Reload data on error
    this.fetchData()
  }

  onActionSuccess = (message) => {
    this.setState({
      message,
      loading: false,
    })

    // Reload data on success
    this.fetchData()
  }

  onActionIgnore = () => {
    this.setState({ loading: false })
  }

  onCellClick = (rowNumber, cellNumber, e, r) => {
    const { data } = this.state
    const { onRowClick, ignoreClick } = this.props

    if (ignoreClick && ignoreClick.includes(cellNumber)) {
      setTimeout(() => {
        // Do not modify the current selection state... (I know, this is ugly, no other quick way to solve this xD)
        const { data } = this.state
        data[rowNumber].selected = !data[rowNumber].selected
        this.setState({ data })
      }, 25)
      return false
    }

    if (cellNumber === -1) {
      // Ignore the checkbox cell
      return
    }

    // e.stopPropagation()
    onRowClick(data[rowNumber], cellNumber)
  }

  onRowsSelected = (selectedRows) => {
    const { data } = this.state

    const newData = data.map((item, index) => {
      switch (selectedRows) {
        case 'all':
          item.selected = true
          break
        case 'none':
          item.selected = false
          break
        default:
          item.selected = selectedRows.indexOf(index) !== -1
      }

      return item
    })

    this.setState({
      data: newData,
    })
  }

  /**
   * Performs action on selected items
   *
   * @param {function} action
   */
  actionSelected = (action) => {
    const { data, loading } = this.state

    if (loading) {
      return
    }

    const selected = data.filter((item) => item.selected)
    if (selected.length > 0) {
      this.setState({ loading: true })
      action(selected, this.onActionSuccess, this.onActionError, this.onActionIgnore)
    }
  }

  handleChangeFilter = (event, index, value) => {
    this.changeFilter(value, true)
  }

  changeFilter = (value: string, refreshData: boolean = true) => {
    console.log('changing filter', value, refreshData)
    this.setState({ filterValue: value }, () => {
      if (refreshData) {
        console.log('refreshing')
        this.fetchData()
      }
    })
  }

  onSearchChange = (event) => {
    this.setState({ searchText: event.target.value }, debounce(this.fetchData, 600))
  }

  renderHeader() {
    const { headers, sizes, centerCells = false, selectable = true } = this.props
    const { data } = this.state
    const showCheckbox = !(selectable === false)

    if (!data || data.length === 0) {
      return null
    }

    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={showCheckbox} enableSelectAll>
        <TableRow>
          {headers.map((item, index) => {
            return (
              <TableHeaderColumn
                key={`header_${index}`}
                tooltip={item}
                style={{
                  padding: 5,
                  textAlign: centerCells ? 'center' : 'left',
                  width: sizes[index] || 'auto',
                }}
              >
                {item}
              </TableHeaderColumn>
            )
          })}
        </TableRow>
      </TableHeader>
    )
  }

  renderData() {
    const { getRowValues, sizes, centerCells = false, selectable = true } = this.props
    const { data } = this.state

    return data.map((row, index) => (
      <TableRow key={`row_${row.id}`} selected={row.selected}>
        {getRowValues(row).map((value, index) => {
          return (
            <TableRowColumn
              key={`cell_${index}`}
              style={{
                textOverflow: 'initial',
                whiteSpace: 'normal',
                padding: 5,
                overflow: 'auto',
                wordWrap: 'break-word',
                width: sizes[index] || 'auto',
                textAlign: centerCells ? 'center' : 'left',
              }}
            >
              {value}
            </TableRowColumn>
          )
        })}
      </TableRow>
    ))
  }

  renderTable() {
    const { data, loading } = this.state
    const { selectable } = this.props

    if (loading) {
      return <CircularProgress className={style.center} />
    }

    if (!data || !data.length) {
      return <div className={style.center}>'Nothing to show...'</div>
    }

    return (
      <Table
        fixedHeader
        selectable={!(selectable === false)}
        filtersVisible
        onRowSelection={this.onRowsSelected}
        onCellClick={this.onCellClick}
        enableSelectAll
        multiSelectable
      >
        {this.renderHeader()}
        <TableBody
          displayRowCheckbox={selectable && data.length > 0}
          deselectOnClickaway={false}
          showRowHover
          stripedRows={false}
        >
          {this.renderData()}
        </TableBody>
      </Table>
    )
  }

  renderPagination() {
    const { page, resourceType } = this.props
    const { loading, meta } = this.state

    if (!meta || loading) {
      return null
    }

    const { pagination } = meta
    if (pagination.total_pages <= 1) {
      return null
    }

    return (
      <Pagination page={+page} totalPages={pagination.total_pages} routeBase={`/${resourceType}`} />
    )
  }

  render() {
    const { actions, buttons, filters, title, filtersVisible = true } = this.props
    const { filterValue } = this.state

    return (
      <MuiThemeProvider muiTheme={theme}>
        <div className={style.wrap}>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle text={title} />
              {filtersVisible && (
                <DropDownMenu value={filterValue} onChange={this.handleChangeFilter}>
                  {filters.map((item, index) => (
                    <MenuItem key={`filter_${index}`} value={item.value} primaryText={item.label} />
                  ))}
                </DropDownMenu>
              )}
              {this.props.searchEnabled && (
                <TextField
                  hintText="Type to search..."
                  value={this.state.searchText}
                  onChange={this.onSearchChange}
                />
              )}
            </ToolbarGroup>
            <ToolbarGroup>
              {buttons.map((item, index) => (
                <RaisedButton key={`button_${index}`} label={item.title} onClick={item.onClick} />
              ))}
              <ToolbarSeparator />
              {actions.length && (
                <IconMenu
                  iconButtonElement={
                    <IconButton touch>
                      <NavigationExpandMoreIcon />
                    </IconButton>
                  }
                >
                  {actions.map((item, index) => (
                    <MenuItem
                      key={`action_${index}`}
                      primaryText={item.title}
                      onClick={() => this.actionSelected(item.onClick)}
                    />
                  ))}
                </IconMenu>
              )}
            </ToolbarGroup>
          </Toolbar>
          {this.renderTable()}
          {this.renderPagination()}
          <Snackbar
            open={!!this.state.message}
            message={this.state.message}
            autoHideDuration={4000}
            onRequestClose={() => this.setState({ message: false })}
          />
        </div>
      </MuiThemeProvider>
    )
  }
}

Grid.defaultProps = {
  actions: [],
  buttons: [],
  includes: [],
  searchEnabled: false,
  page: 1,
  sizes: [],
  onRowClick: () => {},
}

Grid.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  includes: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  resourceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  searchEnabled: PropTypes.bool,
  sizes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ignoreClick: PropTypes.array,

  getRowValues: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  onRowClick: PropTypes.func.isRequired,
}

export default Grid
