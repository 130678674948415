import React, { Component } from 'react'

import axios from 'axios'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table'
import CircularProgress from 'material-ui/CircularProgress'
import { Card, CardActions, CardHeader, CardMedia, CardTitle, CardText } from 'material-ui/Card'
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import DropDownMenu from 'material-ui/DropDownMenu'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'
import MenuItem from 'material-ui/MenuItem'
import NavigationExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more'
import Snackbar from 'material-ui/Snackbar'
import Pagination from 'components/Pagination'
import TextField from 'material-ui/TextField'

import * as utils from 'lib/utils'
import AyuApiClient from 'services/api/AyuApiClient'
import Helmet from 'react-helmet'
import { generatePostPayload } from 'lib/utils'
import { buildImageUrlV2, formatDate } from 'lib/utils'

import { Link } from 'routes'
import Grid from 'components/Grid'

import { Checkbox } from 'material-ui'
import style from './style.css'

const interestDataBasic = [
  { label: 'Connecting with hedge funds', value: 1 },
  { label: 'Connecting with Single Family Offices', value: 2 },
  { label: 'Connecting with Multi Family Offices', value: 3 },
  { label: 'Industry events', value: 4 },
  { label: 'Industry news', value: 5 },
  { label: 'Market insight', value: 6 },
  { label: 'Global risk forecasts', value: 7 },
  { label: 'Capital Introduction', value: 8 },
  { label: 'Hedge fund manager research', value: 9 },
  { label: 'Direct investment opportunities', value: 10 },
  { label: 'Co-investment opportunities', value: 11 },
  { label: 'Venture Capital', value: 12 },
  { label: 'Private Equity', value: 13 },
  { label: 'Real Estate', value: 14 },
  { label: 'Luxury and premium brand perks', value: 15 },
  { label: 'Invitation-only VIP events', value: 16 },
]

class Applications extends Component {
  interests = {}

  constructor(props) {
    super(props)

    this.state = {
      data: [],
      meta: null,
      error: null,
      loading: false,
      message: false,
      filterValue: '',
      searchText: '',
    }

    this.includes = [
      'authorization',
      'description',
      'invite-code',
      'location',
      'role',
      'sent-references',
      'profile-image',
      'similar-applications',
      'similar-applications.authorization',
    ]

    this.filters = [
      { label: 'Latest', value: 'status:fresh;flag:' },
      { label: 'Approved', value: 'status:invited' },
      { label: 'Rejected', value: 'status:rejected' },
      { label: 'All', value: '' },
      { label: 'Future Members', value: 'flag:future-member' },
      { label: 'Service Providers', value: 'flag:service-provider' },
    ]

    this.actions = [
      { title: 'Approve selected', onClick: this.approveSelected },
      { title: 'Reject selected', onClick: this.rejectSelected },
    ]

    interestDataBasic.forEach((interest: { label: string, value: number }) => {
      this.interests[interest.value] = interest.label
    })
  }

  componentDidMount() {
    let defaultFilter = ''
    if (this.filters.length > 0) {
      defaultFilter = this.filters[0].value
    }

    this.setState({ filterValue: defaultFilter }, () => this.fetchData())
  }

  componentDidUpdate(prevProps) {
    const { page } = this.props.query
    const { page: prevPage } = prevProps.query

    if (page !== prevPage) {
      this.fetchData()
    }
  }

  handleChangeFilter = (event, index, value) => {
    this.setState(
      {
        filterValue: value,
      },
      () => this.fetchData()
    )
  }

  onSearchChange = (event) => {
    this.setState({ searchText: event.target.value }, this.fetchData)
  }

  fetchData = () => {
    const { page = 1 } = this.props.query
    const resourceType = 'applications'

    const { filterValue, searchText } = this.state

    const filters = []
    if (filterValue) {
      filters.push(filterValue)
    }

    if (searchText) {
      filters.push(`search:${searchText}`)
    }

    const params = {
      includes: this.includes,
      filters,
      page,
    }

    const requestId = Date.now()

    this.setState(
      {
        loading: true,
        requestId,
      },
      async () => {
        try {
          const result = await AyuApiClient.get(resourceType, params, { requestId })

          console.log('result', result.config.requestId, this.state.requestId)
          if (result.config.requestId !== this.state.requestId) {
            return
          }

          console.log('setting')

          this.setState({
            loading: false,
            data: result.data || [],
            meta: result.meta,
          })
        } catch (result) {
          this.setState({
            error: result.data,
          })
        }
      }
    )
  }

  handleApprove = async (application) => {
    const payload = generatePostPayload(
      'authorizations',
      {},
      {
        application: {
          data: {
            type: 'applications',
            id: application.id,
          },
        },
      }
    )

    if (!application.attributes.emailVerifiedAt) {
      const proceed = confirm(
        'Email address of the selected application has not been verified.' +
          '\nDo you want to proceed anyway?'
      )
      if (!proceed) {
        return
      }
    }
    AyuApiClient.post('authorizations', payload)
      .then((response) => {
        this.fetchData()
      })
      .catch((err) => {
        if (err.status === 405) {
          const response = JSON.parse(err.request.response)
          if (response.user) {
            alert(
              'Application cannot be authorized because email address is already in use by an existing user'
            )
          }
        } else {
          alert('Unexpected error')
        }
      })
  }

  handleReject = async (id) => {
    const message =
      'Are you sure you want to reject this application?\n' +
      'This will send the standard rejection email.\n\n' +
      'To customise the rejection message, press cancel and click through to the full application.'

    if (confirm(message)) {
      const userPayload = generatePostPayload('applications', {
        rejectedAt: Date.now() / 1000,
      })

      await AyuApiClient.patch(
        'applications',
        {
          id,
        },
        userPayload
      )

      this.fetchData()
    }
  }

  handleDelete = async (id) => {
    if (confirm('Are you sure you want to delete selected application?')) {
      await AyuApiClient.delete('applications', { id })
      this.fetchData()
    }
  }

  handleMakeFutureMember = async (id) => {
    if (confirm('Confirm marking the application as "future member"?')) {
      await AyuApiClient.post(`applications/${id}/flag`, { flag: 'future-member' })
      this.fetchData()
    }
  }

  handleMakeServiceProvider = async (id) => {
    if (confirm('Confirm marking the application as "service provider"?')) {
      await AyuApiClient.post(`applications/${id}/flag`, { flag: 'service-provider' })
      this.fetchData()
    }
  }

  handleToggleFreeMembershipFlag = (id, enabled) => {
    return AyuApiClient.post(`applications/${id}/free-membership`, { enabled })
  }

  renderCard = (row, index) => {
    const { attributes, relationships } = row

    const invite = relationships['invite-code']
    const references = relationships['sent-references'] || []
    const { authorization, location, description, role } = relationships
    const profileImage = relationships['profile-image']
    const similarRefs = relationships['similar-applications'] || []
    const interests = attributes.interests || ''

    const formatReferenceName = (reference, symbol) => {
      const { firstName, lastName, email } = reference.attributes
      return (
        <div key={`reference-${reference.id}`}>
          <a href={`http://${email.replace(/.*@/, '')}`} target="_blank">
            {symbol} {firstName} {lastName} ({email}
)
</a>
          <br />
        </div>
      )
    }

    const interestsResult = []
    const interestsIds = interests.split(',')
    if (interests && interestsIds.length) {
      interestsIds.forEach((item) => {
        if (!this.interests[item]) {
          console.log('Missing interest', item)
          return
        }

        interestsResult.push(<div key={`interest_${item}`}>{this.interests[item]}</div>)
      })
    }

    let referenceResults = 'None requested'
    if (references.length) {
      const list = []
      references.forEach((item) => {
        switch (item.attributes.status) {
          case 1:
            list.push(formatReferenceName(item, '✔'))
            break
          case 2:
            list.push(formatReferenceName(item, '✖'))
            break
          default:
            list.push(formatReferenceName(item, '?'))
        }
      })

      referenceResults = list
    }

    const bio = (
      <div className={style.bioWrap}>
        {location && (
          <>
            {location.attributes.name}
            <br />
            <br />
          </>
        )}
        <a href={`http://${attributes.email.replace(/.*@/, '')}`} target="_blank">
          {attributes.email}
        </a>
        <br />
        <br />
        {description && description.attributes.text}
      </div>
    )

    // const inviteCode =
    //   !authorization && !invite ? (
    //     undefined
    //   ) : (
    //     <CardTitle
    //       textStyle={{ paddingRight: 0 }}
    //       style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
    //       title={!authorization ? undefined : 'Authorized'}
    //       subtitle={
    //         !invite
    //           ? undefined
    //           : invite.attributes.type === 1
    //           ? 'Founder invite'
    //           : 'Standard invite'
    //       }
    //     />
    //   )

    const onRequireSubscriptionChange = async (evt, applicationId) => {
      await AyuApiClient.post(`applications/${applicationId}/subscription`, {
        required: evt.target.checked,
      })
      this.fetchData()
    }

    const onfreeBasicMembershipChange = async (evt, applicationId): boolean => {
      this.handleToggleFreeMembershipFlag(applicationId, evt.target.checked)
        .then((response) => {
          if (response.status === 200) {
            this.fetchData()
          } else {
            console.error(response)
            alert('Something went wrong. Please contact the teach team or try again later')
          }
        })
        .catch((response) => {
          console.error(response)
          alert('Something went wrong. Please contact the teach team or try again later')
        })
    }

    const formatSimilarApplication = (similar, symbol) => {
      const { createdAt, rejectedAt, freeAyuMebership } = similar.attributes
      const { authorization } = similar.relationships

      // const status = deletedAt ? '(rejected)' : authorization ? '(accepted)' : '(undecided)'
      const status = rejectedAt ? '✖' : authorization ? '✔' : '?'

      return (
        <div key={`similar-${similar.id}`}>
          <Link route={`/application/${similar.id}`}>
            <a>
              {status} {formatDate(createdAt)}
            </a>
          </Link>
          <br />
        </div>
      )
    }

    const similar = !similarRefs.length ? undefined : similarRefs.map(formatSimilarApplication)
    const isEmailVerified = attributes.emailVerifiedAt || false

    return (
      <Card className={style.card} key={`card-${row.id}`}>
        {/* <CardMedia
          overlay={overlay}
          style={{ minHeight: 300 }}
        >
          {profileImage && <img src={buildImageUrlV2(profileImage, '300x300')} />}
        </CardMedia> */}
        <CardHeader
          title={`${attributes.firstName} ${attributes.lastName}`}
          subtitle={`${attributes.jobTitle} (${attributes.businessName})`}
          textStyle={{ paddingRight: 0 }}
          avatar={buildImageUrlV2(profileImage, '100x100')}
        />
        <CardText>
          <CardHeader
            title={role && role.attributes.title}
            subtitle={bio}
            textStyle={{ paddingRight: 0 }}
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          />
          <CardHeader
            title="Created at"
            subtitle={utils.formatDate(attributes.createdAt)}
            textStyle={{ paddingRight: 0 }}
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          />
          <CardHeader
            title="Verified email"
            subtitle={
              isEmailVerified ? `YES, ${utils.formatDate(attributes.emailVerifiedAt)}` : 'NO'
            }
            textStyle={{ paddingRight: 0 }}
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          />
          <CardHeader
            title="References"
            subtitle={referenceResults}
            textStyle={{ paddingRight: 0 }}
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          />
          {interestsResult.length > 0 && (
            <CardHeader
              title="Interested in"
              subtitle={interestsResult}
              textStyle={{ paddingRight: 0 }}
              style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
            />
          )}
          {similar && (
            <CardHeader
              title="Other applications"
              subtitle={similar}
              textStyle={{ paddingRight: 0 }}
              style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
            />
          )}
          <div style={{ flex: 1, display: 'flex', alignItems: 'center', height: '2rem' }}>
            <Checkbox
              onCheck={(evt) => onRequireSubscriptionChange(evt, row.id)}
              style={{ width: '1rem', display: 'inline-block' }}
              checked={attributes.requiresSubscription}
              disabled={!!attributes.rejectedAt}
            />
            <span style={{ marginLeft: '1rem' }}>Requires subscription</span>
          </div>
          <div style={{ flex: 1, display: 'flex', alignItems: 'center', height: '2rem' }}>
            <Checkbox
              onCheck={(evt) => onfreeBasicMembershipChange(evt, row.id)}
              style={{ width: '1rem', display: 'inline-block' }}
              checked={attributes.freeBasicMembership}
              disabled={!!attributes.rejectedAt}
            />
            <span style={{ marginLeft: '1rem' }}>Free Basic Membership</span>
          </div>
          {attributes.rejectedAt && attributes.rejectionReason && (
            <div style={{ marginTop: 20 }}>
              <p
                style={{
                  borderBottom: '1px solid silver',
                  marginTop: 0,
                  marginBottom: 10,
                  fontWeight: 'bold',
                }}
              >
                AYU Notes
              </p>
              {attributes.rejectionReason}
            </div>
          )}
          {!attributes.rejectedAt && !authorization && (
            <div>
              <CardActions>
                <FlatButton label="View" href={`/application/${row.id}`} />
                <FlatButton label="Approve" onClick={() => this.handleApprove(row)} />
              </CardActions>
              <CardActions>
                <FlatButton secondary label="Reject" onClick={() => this.handleReject(row.id)} />
                <FlatButton secondary label="Delete" onClick={() => this.handleDelete(row.id)} />
              </CardActions>
            </div>
          )}
          {attributes.flag === null && (
            <CardActions>
              <FlatButton
                label="Future Member"
                onClick={() => this.handleMakeFutureMember(row.id)}
              />
              <FlatButton
                label="Service Provider"
                onClick={() => this.handleMakeServiceProvider(row.id)}
              />
            </CardActions>
          )}
        </CardText>
      </Card>
    )
  }

  renderCards() {
    const { data, loading } = this.state

    if (loading) {
      return <CircularProgress className={style.center} />
    }

    if (!data || !data.length) {
      return <div className={style.center}>'Nothing to show...'</div>
    }

    return <div className={style.cards}>{data.map(this.renderCard)}</div>
  }

  renderPagination() {
    const { page = 1 } = this.props.query
    const { meta } = this.state

    if (!meta) {
      return null
    }

    const { pagination } = meta
    if (pagination.total_pages <= 1) {
      return null
    }

    return <Pagination page={+page} totalPages={pagination.total_pages} routeBase="/applications" />
  }

  render() {
    const { filterValue } = this.state
    const title = 'Applications'
    const buttons = []

    return (
      <div className={style.wrap}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarTitle text={title} />
            <DropDownMenu value={filterValue} onChange={this.handleChangeFilter}>
              {this.filters.map((item, index) => (
                <MenuItem key={`filter_${index}`} value={item.value} primaryText={item.label} />
              ))}
            </DropDownMenu>
            <TextField
              hintText="Type to search..."
              value={this.state.searchText}
              onChange={this.onSearchChange}
            />
          </ToolbarGroup>
          <ToolbarGroup>
            {buttons.map((item, index) => (
              <RaisedButton
                key={`button_${index}`}
                label={item.title}
                // onClick={item.onClick}
              />
            ))}
            <ToolbarSeparator />
          </ToolbarGroup>
        </Toolbar>

        {this.renderCards()}
        {this.renderPagination()}
        <Snackbar
          open={!!this.state.message}
          message={this.state.message}
          autoHideDuration={4000}
          onRequestClose={() => this.setState({ message: false })}
        />
      </div>
    )
  }
}

export default Applications
