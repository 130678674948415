import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'routes'

import style from './style.css'

class Pagination extends Component {
  constructor(props) {
    super()

    this.state = {
      pages: this.calculatePages(props.page, props.totalPages),
    }
  }

  componentWillReceiveProps(newProps) {
    const { page, totalPages } = this.props

    if (page !== newProps.page || totalPages !== newProps.totalPages) {
      this.setState({
        pages: this.calculatePages(newProps.page, newProps.totalPages),
      })
    }
  }

  calculatePages(c, m) {
    const current = parseInt(c)
    const last = parseInt(m)
    const delta = 2
    const left = current - delta
    const right = current + delta + 1
    const range = []
    const rangeWithDots = []
    let l

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i)
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1)
        } else if (i - l !== 1) {
          rangeWithDots.push('...')
        }
      }
      rangeWithDots.push(i)
      l = i
    }

    return rangeWithDots
  }

  renderNumber(number, index) {
    const { page, routeBase } = this.props

    const key = `page_${number}_${index}`
    const url = `${routeBase}/${number}`
    const itemClass = page === number ? style.active : style.page

    if (number === '...') {
      return (
        <li key={key} className={style.dots}>
          ...
        </li>
      )
    }

    return (
      <li key={key} className={itemClass}>
        <Link route={url}>
          <a>{number}</a>
        </Link>
      </li>
    )
  }

  render() {
    const { pages } = this.state

    return (
      <div className={style.pagination}>
        <ul>{pages.map((page, index) => this.renderNumber(page, index))}</ul>
      </div>
    )
  }
}

Pagination.defaultProps = {
  totalPages: 1,
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  routeBase: PropTypes.string.isRequired,
}

export default Pagination
